<template>
  <!-- begin::FaresStandard via Excel -->
  <div>
    <div class="manager-title">
      <div>
        {{ $t("FARES.STANDARD.EXCEL.TITLE_2") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <div class="search-info my-6">
        <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
        {{ $t("FARES.STANDARD.EXCEL.INFO_GENERAL") }}
      </div>

      <div class="row my-9">
        <div class="col-lg-12 text-center">
          <button
            class="btn btn-pill button-form btn-primary mr-9 px-9"
            @click="downloadTemplate"
            :disabled="Downloading"
          >
            <inline-svg src="/media/icons/download-alt-2.svg" class="mr-3" />
            {{ $t("FARES.STANDARD.EXCEL.DOWNLOAD") }}
          </button>

          <span v-if="!Uploading">
            <input
              type="file"
              ref="inputFileExcel"
              style="display: none"
              @change="uploadExcel($event.target.files)"
              accept=".xlsx"
            />
            <button
              class="btn btn-pill button-form btn-tertiary mr-9 px-9"
              @click="$refs.inputFileExcel.click()"
              :disabled="Uploading"
            >
              <inline-svg src="/media/icons/upload-alt-2.svg" class="mr-3" />
              {{ $t("FARES.STANDARD.EXCEL.UPLOAD") }}
            </button>
          </span>

          <button
            v-else
            class="btn btn-pill button-form btn-tertiary mr-9 px-9"
            disabled
          >
            <inline-svg src="/media/icons/upload-alt-2.svg" class="mr-3" />
            {{ $t("GENERIC.PROCESSING_WAIT") }}...
          </button>

          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="$router.push('/manager/fares?ActiveTab=standard')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>
        </div>
      </div>

      <div v-if="ShowResultsTable && ResultsDetails">
        <div class="row my-6">
          <div class="col-lg-12">
            <div class="search-info">
              <inline-svg
                class="mx-3"
                src="/media/icons/help-alt-5-success.svg"
              />
              <strong>
                {{
                  $t("FARES.STANDARD.EXCEL.RESULTS") +
                    ": " +
                    $t("FARES.STANDARD.EXCEL.CREATED_ROWS", {
                      count: ResultsDetails.CreatedRows
                    }) +
                    ", " +
                    $t("FARES.STANDARD.EXCEL.UPDATED_ROWS", {
                      count: ResultsDetails.UpdatedRows
                    }) +
                    ", " +
                    $t("FARES.STANDARD.EXCEL.ERROR_ROWS", {
                      count: ResultsDetails.ErrorRows
                    })
                }}
              </strong>
            </div>
          </div>
        </div>

        <div v-if="ResultsDetails.ErrorRows > 0" class="row">
          <div class="col-lg-6">
            <v-simple-table id="ResultErrorsTable">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-primary">
                      {{ $t("FARES.STANDARD.EXCEL.ROW") }}
                    </th>
                    <th class="text-primary">
                      {{ $t("FARES.STANDARD.EXCEL.CAUSE") }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in ResultsDetails.ErrorList"
                    :key="index"
                  >
                    <td style="width: 25%">
                      <strong>{{ item.Row }}</strong>
                    </td>
                    <td>
                      {{ $t("FARES.STANDARD.EXCEL.ERROR_" + item.Code) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>

        <div v-if="ResultsDetails.ErrorRows > 0" class="row my-6 mx-3">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="$router.push('/manager/fares?ActiveTab=standard')"
          >
            <inline-svg
              src="/media/icons/arrow-return-primary.svg"
              class="mr-3"
            />
            {{ $t("GENERIC.BACK") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- end::FaresStandard via Excel -->
</template>

<style scoped>
#ResultErrorsTable th {
  font-size: 1em;
  font-weight: bolder;
}
</style>

<script>
import FareStandardService from "@/core/services/api/v2/farestandard.service";

export default {
  name: "FaresStandarExcel",
  data() {
    return {
      Downloading: false,
      Uploading: false,
      ShowResultsTable: false,
      ResultsDetails: null
    };
  },
  methods: {
    downloadTemplate() {
      // Disable download button, because this may take a while
      this.Downloading = true;

      FareStandardService.downloadExcel().then(rawResponse => {
        if (rawResponse === 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.SAVE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          const tempBlob = new Blob([rawResponse], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const tempLink = document.createElement("a");
          tempLink.href = URL.createObjectURL(tempBlob);
          tempLink.download = this.$t("FARES.STANDARD.EXCEL.DOWNLOAD_NAME");
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        // Re-enable button
        this.Downloading = false;
      });
    },

    uploadExcel(newFile) {
      if (newFile) {
        // Might re-check MIME-type, but must rely on file extension, easy to tamper - check on Laravel side

        // Disable submit button & results table
        this.Uploading = true;
        this.ShowResultsTable = false;
        this.ResultsDetails = [];

        FareStandardService.uploadExcel(newFile[0]).then(rawResponse => {
          if (rawResponse.CreatedRows === undefined) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (rawResponse === 400) {
              whyError = this.$i18n.t("FARES.STANDARD.EXCEL.ERROR_400");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.ResultsDetails = rawResponse;
            this.ShowResultsTable = true;

            this.$bvToast.toast(" ", {
              title: this.$i18n.t("FARES.STANDARD.EXCEL.UPLOADED_OK"),
              variant: "success",
              solid: true
            });
          }

          // Reset file & button
          this.Uploading = false;
          this.$nextTick(() => {
            this.$refs.inputFileExcel.value = null;
          });
        });
      }
    }
  }
};
</script>
